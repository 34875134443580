<template>
  <!-- The CSS grid that makes up the entirety of the hero image/banner image area -->
  <section class="top-banner-section">
    <!-- The CSS grid area that displays the image (layer 1) -->
    <div class="banner-image-div">
      <img class="banner-image" src="/kflag.jpg" alt="Banner Image" />
    </div>
    <!-- The CSS grid area that displays the semi-transparent gradient overlay (layer 2) -->
    <div class="banner-overlay-div"></div>
    <!-- The CSS grid area that displays the content (layer 3) -->
    <div class="banner-text-div">
      <span class="banner-text">
        <p class="banner-h1-text">더 이상은 참을 수 없습니다!</p>
        <!--- <p class="banner-body-text">부끄럽지 않은 대한민국이 보고싶습니다.</p>--->
        <!---<p class="banner-btn"><a class="banner-btn-item" href="https://www.cambermast.com">Get started &#8594;</a></p>--->
      </span>
    </div>
  </section>
</template>

<script>
export default {
  name: "hero-vue",
  computed: {},
};
</script>

<style lang="scss" scoped>
// Create three grid areas (boxes) that overlap each other, essentially creating three layers.
// Grid box 1 (layer 1): Image
// Grid box 2 (layer 2): Gradient overlay
// Grid box 3 (layer 3): Call to action text/content
.top-banner-section {
  display: grid;
  grid-template-columns: 1fr; // stretch to the full frame
  grid-template-rows: 350px; // 350 pixels tall
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-content: center;
  justify-content: center;

  .banner-image-div {
    grid-area: 1 / 1 / 2 / 2;
  } // image
  .banner-overlay-div {
    grid-area: 1 / 1 / 2 / 2;
  } // gradient or other overlay
  .banner-text-div {
    grid-area: 1 / 1 / 2 / 2;
  } // overlay objects like text, buttons, etc.
}

// Banner image (layer 1)
.banner-image {
  display: grid;
  min-width: 350px; // Do not resize to smaller than this.
  width: 100%;
  height: 100%;
  object-fit: cover; // Using this so the image can be any size and still look halfway decent.
}

// Gradient overlay (layer 2)
// gradient overlay going from black to transparent.
// note: search for a gradient overlay generator to make this easier.
.banner-overlay-div {
  display: grid;
  max-width: 100%;
  background: black;
  background: linear-gradient(
    60deg,
    rgba(0, 0, 0, 0.7777485994397759) 30%,
    rgba(255, 255, 255, 0) 100%
  ); // start at black at the bottom left'ish and goes at a 60% angle. This will make the white easy to read with nearly any image.
}

// Banner html components (layer 3)
// banner text
.banner-text-div {
  display: grid;
  align-items: center;
  margin-left: 55px;
  margin-right: 55px;
}

// Typograhy: *** This is all the stuff you change

.banner-h1-text {
  // font can get larger, but no smaller than 10 points.
  font-size: calc(40pt + 0.15vw);
  letter-spacing: 0.05em;
  font-weight: bolder;
  text-transform: uppercase;
  color: white;
}

.banner-body-text {
  // font can get larger, but no smaller than 10 points.
  font-size: calc(15pt + 0.15vw);
  margin-top: 0.5em;
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
  }

  &:visited {
    color: white;
  }

  &:active {
    color: white;
  }
}

.banner-btn {
  margin-top: 1em;
}

.banner-btn-item {
  font-size: calc(8pt + 0.15vw); // responsive size, but keep a minimum.
  padding-top: calc(0.5em + 0.08vw);
  padding-bottom: calc(0.5em + 0.08vw);
  padding-left: calc(0.5em + 0.08vw);
  padding-right: calc(0.5em + 0.08vw);
  color: blue;
  background-color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid white;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }
}
</style>